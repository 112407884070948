.header {
  border: 0;
  height: 9.8rem;
  /* Add min-height to prevent flex-grow to shrink it */
  min-height: 9.8rem;
  padding: 0 3.5rem;
  display: flex;
  background: var(--header-bg-gradient);
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100.4rem;
  margin: 0 auto;
}

/* This is needed for the site to be usable on very small
screen sizes */
@media screen and (max-width: 470px) {
  .header {
    padding: 0 2rem;
  }
}

@media screen and (min-width: 769px) {
  .header {
    background: var(--header-bg-color);
  }
}