.menu {
    display: flex;
    height: 100%;
    font-size: var(--font-size-lg);
}

.nav {
    position: absolute;
    box-sizing: border-box;
    background: var(--header-bg-color);
    top: 9.8rem;
    width: 100%;
    display: none;
    left: 0;
    padding: 0 2rem 2rem 3.5rem;
    font-size: var(--font-size-xl);
    z-index: 1000;
}

@media screen and (min-width: 769px) {
    .nav {
        position: relative;
        background: transparent;
        display: flex;
        left: 0;
        top: 0;
        padding: 0;
        font-size: var(--font-size-lg);
        z-index: 1;
    }
  }

.menuLink {
    display: flex;
    align-items: center;
    margin-top: 0.8rem;
    padding-left: 1.7rem;
    border-left: 0.2rem solid transparent;
}

@media screen and (min-width: 769px) {
    .menuLink {
        border-left: 0;
        padding: 0;
        border-bottom: 0.2rem solid transparent;
        margin: 0 1.7rem;
    }
    .menuLink:last-of-type {
        margin: 0 0 0 1.7rem;
    }
  }

.langDropdown {
    margin-left: 4.3rem;
}

/* This is needed for the site to be usable on very small
screen sizes */
@media screen and (max-width: 470px) {
    .langDropdown {
        margin-left: 0.5rem;
    }
}

.menuLinkActive {
    border-left: 0.2rem solid var(--menu-active-color);
}

.menuLink:hover {
    border-left: 0.2rem solid var(--menu-active-color);
}

.menuLink:active {
    border-left: 0.2rem solid var(--menu-active-color);
}

@media screen and (min-width: 769px) {
    .menuLink:hover {
        border-left: 0;
        border-bottom: 0.2rem solid var(--menu-active-color);
    }

    .menuLink:active {
        border-left: 0;
        border-bottom: 0.2rem solid var(--menu-active-color);
    }

    .menuLinkActive {
        border-left: 0;
        border-bottom: 0.2rem solid var(--menu-active-color);
    }
}