html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Inter, Verdana, Helvetica, Arial, sans-serif;
  height: 100%;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--font-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background: var(--bg-color);
  -webkit-text-size-adjust: 100%;
}

#root {
  height: 100%;
  font-size: var(--font-size-md);
}

a {
  text-decoration: none;
  color: inherit;
}