.toggleButton {
    composes: button from global;
    border: 0;
    width: auto;
    height: auto;
    display: block;
    align-self: center;
    margin-left: 3.5rem;
}

.toggleButtonLight {
    composes: toggleButton
}

.toggleButtonDark {
    composes: toggleButton
}

@media screen and (min-width: 769px) {
    .toggleButton {
      display: none;
    }
}

/* This is needed for the site to be usable on very small
screen sizes */
@media screen and (max-width: 470px) {
    .toggleButton {
        margin-left: 1.2rem;
    }
}