.toggleButton {
    composes: button from global;
    border: 0;
    font-weight: 400;
    width: auto;
    height: auto;
    display: block;
    color: var(--header-lang-toggle-color);
    padding: 0 1.2rem;
    min-width: 5.54rem;
    box-sizing: border-box;
}

.dropdownMenu {
    display: block;
    margin: 0;
    padding: 0.8rem 0;
    list-style: none;
    position: absolute;
    top: 9.8rem;
    background: var(--header-bg-color);
    z-index: 1000;
}

.dropdownMenuItem {
    font-family: inherit;
    width: 100%;
    padding: 0.6rem 1.6rem;
    clear: both;
    font-weight: 400;
    color: inherit;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background: transparent;
    border: 0;
    font-size: var(--font-size-lg);
    cursor: pointer;
}

.dropdownMenuItem:hover {
    background: rgba(255,255,255,0.05);
}


.dropdown {
    display: flex;
}

